/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import BannerImage from "../images/social-media.jpg"
import { getSchemaOrgJSONLD } from "./schema"

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  isBlogPost,
  url,
  image,
  datePublished,
  isProduct,
  price,
}) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `
  )
  const baliseTitle = title ? title : data.site.siteMetadata.title
  const metaDescription = description
    ? description
    : data.site.siteMetadata.description
  const baliseUrl = url
    ? data.site.siteMetadata.siteUrl + url
    : data.site.siteMetadata.siteUrl
  const ogImage = image ? image : BannerImage

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    baliseUrl,
    baliseTitle,
    ogImage,
    metaDescription,
    datePublished,
    isProduct,
    price,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={baliseTitle}
      titleTemplate={`%s | SLAP digital`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: baliseTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:url`,
          content: baliseUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `og:site_name`,
          content: `SLAP digital`,
        },
        {
          name: `twitter:creator`,
          content: `@SLAPdigital`,
        },
        {
          name: `twitter:title`,
          content: baliseTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `KNlntFlD2_scu_wHjrX1Fi2OsjlZbbDbsEDfC_9ZQ9o`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {url === "/" || url === "/en" ? (
        <link
          data-react-helmet="true"
          rel="alternate"
          href="https://www.slapdigital.fr/en/"
          hreflang="en"
        />
      ) : null}
      {url === "/" || url === "/en" ? (
        <link
          data-react-helmet="true"
          rel="alternate"
          href="https://www.slapdigital.fr/"
          hreflang="fr-fr"
        />
      ) : null}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
